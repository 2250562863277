import React from 'react';
import {useStore} from '@stores/index';
import {isUndefined} from '@lepicard/utils';
import {ApproDeliveriesRowByDelivery} from '@components/ApproDeliveries/ApproDeliveriesRows/ApproDeliveriesRowsByDelivery';
import {CardList} from '@components/UI/Card/CardList';
import {ApproDeliveriesRowByProduct} from '@components/ApproDeliveries/ApproDeliveriesRows/ApproDeliveriesByProduct';
import {ApproDeliveriesRowByFamily} from '@components/ApproDeliveries/ApproDeliveriesRows/ApproDeliveriesRowByFamily';

export interface IApproDeliveriesRow {
  data: any;
}

export const ApproDeliveriesRows: React.FC = () => {
  const {approDeliveriesStore} = useStore();
  const list = approDeliveriesStore.list.toJSON();

  return (
    <CardList>
      {list.map((data: any) => {
        if (!isUndefined(data.no)) {
          return <ApproDeliveriesRowByDelivery key={data.no} data={data} />;
        }
        if (!isUndefined(data.description)) {
          return <ApproDeliveriesRowByProduct key={data.description} data={data} />;
        }
        if (!isUndefined(data.familyCode)) {
          return <ApproDeliveriesRowByFamily key={data.familyCode} data={data} />;
        }
        return null;
      })}
    </CardList>
  );
};
