import React, {useContext, useEffect, useState} from 'react';
import {Route, useLocation, Navigate, Routes, BrowserRouter, Outlet} from 'react-router-dom';
import NotFound from './components/NotFound';
import Login from './containers/Login';
import NavContainer from '@components/Layout/NavContainer';
import {observer} from 'mobx-react-lite';
import {useStore} from './stores';
import Profile from '@containers/Profile';
import LoginContainer from '@components/Layout/LoginContainer';
import routesUrl, {collectRoutes, servicesRoutes, approRoutes} from '@routes';
import Home from '@containers/Home';
import Quotations from '@containers/Quotations';
import Adivalor from '@containers/Adivalor';
import CollectOffers from '@containers/CollectOffers';
import Activation from '@containers/Activation';
import TestExport from '@containers/TestExport';
import Contracts from '@containers/Contracts';
import CollectOptions from '@containers/CollectOptions';
import Deliveries from '@containers/Deliveries';
import Accounting from '@containers/Accounting';
import {useNavigate} from 'react-router-dom';

import ServiceOffers from '@containers/ServiceOffers';
import ServiceFds from '@containers/ServiceFds';

import ApproOffers from '@containers/ApproOffers';
import ApproContracts from '@containers/ApproContracts';
import ApproOrders from '@containers/ApproOrders';
import Durability from '@containers/Durability';
import DurabilitySuccess from '@containers/Durability/durabilitySuccess';

import {ApproInvoices} from '@containers/ApproInvoices';
import ApproSoldOrders from '@containers/SoldOrders';
import ApproDeliveries from '@containers/ApproDeliveries';

import Representative from '@containers/Representative';

import Cgv from '@components/Cgv';
import LegalesNotice from '@components/legales-notices';

import ScrollToTop from '@utils/scrollToTop';
import BulletinsSanteDuVegetal from '@containers/BulletinsSanteDuVegetal';
import {AuthContext, IAuthContext} from 'react-oauth2-code-pkce';
import {isDef} from '@lepicard/utils';
import Loader from '@components/Loader';
import EnvironmentService from '@utils/environmentService';
import Logout from '@containers/Logout';
import {CollectInvoices} from '@containers/CollectInvoices';
import {ServiceErrorEvent} from '@stores/models';
import SessionExpired from '@containers/SessionExpired';

const instanceConfig = EnvironmentService.getInstanceConfig();

const Router: React.FC = observer(() => {
  const {hydrated} = useStore();

  const location = useLocation();
  // get user infos
  const {campaigns: campaignsStore} = useStore();
  const {tokenData, token, logIn, logOut, idToken, error, loginInProgress}: IAuthContext = useContext(AuthContext);
  const {sso: SSOStore} = useStore();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (token !== '' && SSOStore.sessionExpired) {
      SSOStore.setExpired(false);
      SSOStore.retrieveAdditionalSSOInformation(token);
    }
  }, [location, token, SSOStore.sessionExpired]);

  useEffect(() => {
    if (SSOStore.additionalInformationsState.hasFailed) {
      logOut();
      SSOStore.additionalInformationsState.resetState();
    }
  }, [SSOStore.additionalInformationsState.hasFailed, logOut]);

  useEffect(() => {
    const handleServiceError: EventListener = (event: Event) => {
      if (event instanceof CustomEvent && event.type === 'serviceError') {
        const serviceErrorEvent = event as ServiceErrorEvent;

        console.log('ServiceError', serviceErrorEvent);

        if (
          typeof serviceErrorEvent.detail?.error === 'object' &&
          serviceErrorEvent.detail?.error !== null &&
          'message' in serviceErrorEvent.detail.error &&
          typeof serviceErrorEvent.detail.error.message === 'string'
        ) {
          console.error('setErrorMessage', serviceErrorEvent.detail.error.message);
          setErrorMessage(serviceErrorEvent.detail.error.message);
        }

      }
    };

    window.addEventListener('serviceError', handleServiceError);
    return () => window.removeEventListener('serviceError', handleServiceError);
  }, []);

  useEffect(() => {
    console.log('UseEffet errorMessage', errorMessage);
    if (errorMessage && ['Cannot get user information through api/me', 'Not authenticated'].includes(errorMessage)) {
      logOut();
      navigate(routesUrl.SESSION_EXPIRED);
    }
  }, [errorMessage]);

  // END get user infos

  useEffect(() => {
    if (campaignsStore.list.length === 0 && isDef(SSOStore.user) && token !== '') {
      campaignsStore.fetchCampaigns();
    }
  }, [location, SSOStore.user]);

  const isLoginPending = loginInProgress || !hydrated || SSOStore.additionalInformationsState.isPending;
  const isUserInformationLoading = token !== '' && !isDef(SSOStore.user);

  if (isLoginPending || isUserInformationLoading) {
    return <Loader className="fullScreenLoader" />;
  }

  const subRoute404 = <Route path="*" element={<NotFound />} />;

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path={routesUrl.ACTIVATION} element={<LoginContainer><Activation /></LoginContainer>} />
        <Route path={routesUrl.LOGOUT} element={<LoginContainer><Logout /></LoginContainer>} />
        <Route path={routesUrl.SESSION_EXPIRED} element={<LoginContainer><SessionExpired /></LoginContainer>} />
        <Route path={routesUrl.LOGIN} element={token !== '' ?
          <Navigate to="/" replace={true} /> :
          <LoginContainer><Login /></LoginContainer>}
        />

        <Route path="/" element={token === '' ? <Navigate to="/login" replace /> : <NavContainer />}>
          <Route index element={<Home />} />

          <Route path={'/test-export'} element={<TestExport />} />

          <Route path={routesUrl.CGV} element={<Cgv />} />

          <Route path={routesUrl.LEGALES_NOTICE} element={<LegalesNotice />} />

          <Route path={routesUrl.PROFILE} element={<Profile />} />

          <Route path={routesUrl.REPRESENTATIVES} element={<Representative />} />


          <Route path={routesUrl.DURABILITY}
                 element={
                   instanceConfig.durabilite.enablePage ?
                     <Durability /> :
                     <NotFound />
                 } />


          <Route path={routesUrl.DURABILITYSUCCESS} element={<DurabilitySuccess />} />

          <Route path={routesUrl.QUOTATIONS} element={<Quotations />} />

          <Route path={routesUrl.CONTRACTS} element={<Contracts />} />

          <Route path={routesUrl.DELIVERIES} element={<Deliveries />} />

          <Route path={routesUrl.ACCOUNTING} element={<Accounting />} />

          <Route path={routesUrl.COLLECT}>
            <Route path={collectRoutes.INVOICES} element={<CollectInvoices />} />
            <Route path={collectRoutes.OFFERS} element={<CollectOffers />} />
            <Route path={collectRoutes.OPTIONS} element={<CollectOptions />} />
            {subRoute404}
          </Route>

          <Route path={routesUrl.APPRO}>
            <Route path={approRoutes.APPRO_CONTRACTS} element={
              instanceConfig.approContrats.enablePage ?
                <ApproContracts /> :
                <NotFound />
            } />
            <Route path={approRoutes.APPRO_OFFERS} element={<ApproOffers />} />
            <Route path={approRoutes.APPRO_ORDERS} element={<ApproOrders />} />
            <Route path={approRoutes.APPRO_INVOICES} element={<ApproInvoices />} />
            <Route path={approRoutes.APPRO_SOLD_ORDERS} element={<ApproSoldOrders />} />
            <Route path={approRoutes.APPRO_DELIVERIES} element={<ApproDeliveries />} />
            {subRoute404}
          </Route>

          <Route path={routesUrl.SERVICES}>
            <Route path={servicesRoutes.BSV} element={
              instanceConfig.bsv.enablePage ?
                <BulletinsSanteDuVegetal /> :
                <NotFound />
            } />
            <Route path={servicesRoutes.ADIVALOR} element={<Adivalor />} />
            <Route path={servicesRoutes.OFFERS} element={<ServiceOffers />} />
            <Route path={servicesRoutes.FDS} element={
              instanceConfig.fds.enablePage ?
                <ServiceFds /> :
                <NotFound />
            } />
            {subRoute404}
          </Route>

          {subRoute404}
        </Route>
      </Routes>
    </>
  );
});

export default Router;
